import React from 'react';

// SVG components
const NextArrowSVG = () => (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.845703" width="39.5" height="39.3081" rx="19.6541" stroke="#575757" stroke-opacity="0.5"/>
    <path d="M12 20.4998H28.5M21.075 12.3457L28.5 20.4998L21.075 28.6538" stroke="white" stroke-width="3.17"/>
    </svg>
)

const PrevArrowSVG = () => (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="40.5" y="40.1538" width="39.5" height="39.3081" rx="19.6541" transform="rotate(-180 40.5 40.1538)" stroke="#575757" stroke-opacity="0.5"/>
    <path d="M29 20.4997H12.5M19.925 28.6538L12.5 20.4997L19.925 12.3457" stroke="white" stroke-width="3.17"/>
    </svg>
    
    
);

// Custom arrow components
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '10px', zIndex: 1, cursor: 'pointer' }}
      onClick={onClick}
    >
      <NextArrowSVG />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '10px', zIndex: 1, cursor: 'pointer' }}
      onClick={onClick}
    >
      <PrevArrowSVG />
    </div>
  );
};

export { NextArrow, PrevArrow };

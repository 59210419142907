import React, { useState, useEffect } from 'react';
import '../css/about.css';
import '../css/globalStyles.css';
import images from '../components/images';
import { Link } from 'react-router-dom';
import { motion, } from "framer-motion";

function Aboutt() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);


  const [isCircleActive3, setIsCircleActive3] = useState(false);
    const toggleCircle3 = () => {
      setIsCircleActive3(!isCircleActive3);
    };

  return (
    <div className={`welcome ${mounted ? 'slide-inRight' : 'slide-outRight'}`}>
      <div className="innerrr">
        <div className='innerrTop'>
          <Link to="/Welcome">
            <img alt='back button' className='backbutton' src={images.backB}/>
          </Link>
          <div className='innerrTopRight'>
            <h1 className='aboutH1'>The Story Of <span className="aboutItalic">TheTrybeCo</span><span style={{ color: 'red' }}>.</span></h1>
          </div>
        </div>
        <motion.div className='aboutmiddle'>
          <motion.div className='row'>
            <p>In 2018, Seyi & friends had a realization: everything around us is ugly. We need to make it look better. With that ambitious mission in mind, TheTrybeCo was born. He knew immediately (and adjusted accordingly) that there was a tech tsunami on the horizon—and early adoption was our only way forward. <br/><br/>
            With a commitment to constant evolution and process improvement, we’ve lent our refined expertise to major projects for some of the world’s most prominent brands. We believe that success is achieved through a highly collaborative interaction, so that we can work.</p>
          </motion.div>
          <motion.div className='row'>
          <p>In 2018, Seyi & friends had a realization: everything around us is ugly. We need to make it look better. With that ambitious mission in mind, TheTrybeCo was born. He knew immediately (and adjusted accordingly) that there was a tech tsunami on the horizon—and early adoption was our only way forward. <br/><br/>
            With a commitment to constant evolution and process improvement, we’ve lent our refined expertise to major projects for some of the world’s most prominent brands. We believe that success is achieved through a highly collaborative interaction, so that we can work.</p>
          </motion.div>
        </motion.div>
        <div className='gateContainer'>
        <div className="gate">
          <span className='dis'><p style={{ fontSize: '16px' }}>There's still a lot more to see.</p></span>
        </div>
        
        <div className="containerr">
          <div className="joinBtnn" onMouseEnter={toggleCircle3} onMouseLeave={toggleCircle3}>
            <div className={`circleLong ${isCircleActive3 ? 'active' : ''}`}></div>
            <Link to="/Values" style={{ textDecoration: 'none' }} >
              <p className="btnOpenForm">Continue Your Journey</p>
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutt
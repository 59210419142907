import React, { useState, useEffect } from "react";
import "../css/service.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
import MultipleCardsSlider from "../components/MultipleCardsSlider";
function Service() {
  
  
  const [mounted, setMounted] = useState(false);

 

  


  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3)
  };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
        
          <div className="innerry">
               <div className="innerrTopp">
                    <Link to="/Process">
                        <img alt='back button' className='backbutton' src={images.backB}/>
                    </Link>
                    <div className='innerrTopRight'>
                        <h1 className='aboutH1'>Our <span className="aboutItalic">Service</span><span style={{ color: 'red' }}>.</span></h1>
                        <p className="headSubtext">Discover what theTrybeCo does</p>
                    </div>
              
                </div>
              
              <div className="serviceContainer">
                <div className="a998"><p>At TheTrybeCo, we offer a wide range of services designed to address the unique needs of our clients. From custom software development to web design and mobile app development, our team of skilled professionals is equipped to tackle projects of any scale. We pride ourselves in our ability to deliver tailored solutions that align with our client's goals and objectives. Whether you are a startup looking to establish your online presence or an established business in need of a digital transformation, we have the expertise to bring your vision to life.</p></div>
                <MultipleCardsSlider />
              </div>
        
      </div>
      
          <div className="containerrr">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                  className={`circleLong ${isCircleActive3 ? "active" : ""}`}>
                    
              </div>
              <Link to="/Projects" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        
    </div>
  );
}

export default Service;

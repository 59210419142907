import { Link, useNavigate } from "react-router-dom";

function LinkButton({ children, to }) {
  const navigate = useNavigate();
//   const className = "";

  if (to === "-1") return <i onClick={() => navigate(-1)} style={{cursor: "pointer"}}>{children}</i>;

  return (
    <Link to={to} >
      {children}
    </Link>
  );
}

export default LinkButton;

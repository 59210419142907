import Images from "./images";
const projectsData = [
    {
        question: "LAWVERSE MAGAZINE",
        answer: "LAWVERSE MAGAZINE LTD",
        image: Images.law,
        category: "Website 2024",
        link: "https://www.lawverse.com.ng"
      },
      {
        question: "STASOUL NIG LTD",
        answer: "STASOUL NIG LTD",
        image: Images.stasoul,
        category: "Website 2023",
        link: "https://www.stasoul.com"
      },
      {
        question: "ASSESSMENT SYSTEM",
        answer: "ASSESSMENT SYSTEM",
        image: Images.assessment,
        category: "Website 2023",
        link: "https://www.lawverse.com.ng"
      },
      {
        question: "EMPLACE COLLEGE",
        answer: "EMPLACE COLLEGE INT.",
        image: Images.emp,
        category: "Website 2022",
        link: "https://www.emplacecollege.org.ng"
      },
      {
        question: "MUMMY'S PLACE",
        answer: "MUMMY'S PLACE INT.",
        image: Images.mp,
        category: "Website 2021",
        link: "https://www.mummysplace.org.ng"
      }, 
];

export default projectsData;
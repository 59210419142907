import React, { useState } from 'react';
import '../App.css';
import '../css/globalStyles.css';
import images from '../components/images';
import { Link } from 'react-router-dom';
function Home() {

    const [isCircleActive3, setIsCircleActive3] = useState(false);
    const toggleCircle3 = () => {
      setIsCircleActive3(!isCircleActive3);
    };
  return (
    <>
      <div class="factsContainer">
            <div class="factsHead">
              <img alt='hover' width="100" src={images.Group1}/>
              <div id="factBtn" class="factBtn">
                <div className='tech' >Tech Facts of The Day</div>
              </div>
            </div>
            <div id="abs" class="facts">
              <h4 style={{fontFamily: 'Poppins'}}>Did You Know <span
               style={{
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#ba0000',
                fontSize: '20px'
               }}
                >?</span></h4>
                <p id="factstext">Augusta Ada King was the first programmer and was the daughter of a mad poet. Her mother promoted her interest in math and logic in an effort to prevent her from ending up like her father</p>
            </div>
          </div>
          <img className='backgroundBar' src={images.bars} />
          <div class="inner">
            <button class="btn" >TheTrybeCo<span style={{color: 'red'}}>.</span></button>
              <h1>Where <span class="italics">Collaboration</span> Meets <span class="italics">Innovation</span><span style={{color: 'red'}}>.</span></h1>
              <p class="headSubtext">We believe that success is achieved through a highly collaborative interaction, so that we can work.</p>
              <p id='test' class="headSubtext2">We are glad you chose to explore our TRYBE. 
                You have been granted access please proceed below.</p>
              <div class="gate">
                  <img alt='thetrybeco' width="30" src={images.Highlight} />
                  <p>Here Is A Passage Way</p>
              </div>
              <div class="container">
                <div class="joinBtn" onMouseEnter={toggleCircle3} onMouseLeave={toggleCircle3}>
                  <div className={`circle1 ${isCircleActive3 ? 'active' : ''}`}></div>
                  <Link to="Welcome" style={{ textDecoration: 'none' }} >
              <p className="btnOpenForm">Enter TheTrybeCo</p>
            </Link>
                </div>
                    <img alt='arrow' width="70" src={images.Group2} />
              </div>
          </div>
          <Link to="Aboutt" style={{textDecoration: 'none'}} class="aboutContainer">
              <div id='dropdownAbout' class="aboutHead">
                <h4 style={{fontWeight: 400, fontFamily: 'Poppins'}} >Who Are We?</h4>
              </div>
              <div class="about" id='aboutText'>
                  <p><span style={{fontWeight: 700, fontFamily: 'Poppins'}}>TheTrybeCo <span style={{color: '#ba0000', fontWeight: 700,}}>.</span></span> is a tech agency focused on providing exceptional services with complete trust. We believe that success is achieved through highly collaborative interactions — One TRYBE at a time</p>
              </div>
          </Link>
    </>
  )
}

export default Home
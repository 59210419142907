import logo from "../img/logo.png";
import background from "../img/background.png";
import background1 from "../img/background1.png";
import background2 from "../img/background2.png";
import Group1 from "../img/Group1.png";
import Group2 from "../img/Group2.png";
import Highlight from "../img/Highlight.png";
import menu from "../img/menu.png";
import facebook from "../img/Vectorfacebook.png";
import Google from "../img/VectorGoogle.png";
import X from "../img/VectorTwitter.png";
import backB from "../img/backB.png";
import trybe1 from "../img/trybe1.png";
import trybe2 from "../img/trybe2.png";
import trybe3 from "../img/trybe3.png";
import seyi from "../img/seyi.png";
// Add the Logos for the Testimonial Screen
import googleLogo from "../img/googleLogo.svg";
import walmartLogo from "../img/walmartLogo.svg";
import mondayLogo from "../img/mondayLogo.svg";
import logitechLogo from "../img/logitechLogo.svg";
import paypalLogo from "../img/paypalLogo.svg";
import forwardButton from "../img/forwardButton.svg";
import backButton from "../img/backButton.svg";
// added gallery
import gallery1 from "../img/gallery1.png";
import gallery2 from "../img/gallery2.png";
import gallery3 from "../img/gallery3.png";
import gallery4 from "../img/gallery4.png";
import gallery5 from "../img/gallery5.png";
import gallery6 from "../img/gallery6.png";
import gallery7 from "../img/gallery7.png";
import gallery8 from "../img/gallery8.png";
import gallery9 from "../img/gallery9.png";
import gallery10 from "../img/gallery10.png";
import gallery11 from "../img/gallery11.png";
// added culture icons
import cultureicon1 from "../img/cultureicon1.png";
import cultureicon2 from "../img/cultureicon2.png";
import cultureicon3 from "../img/cultureicon3.png";
import cultureicon4 from "../img/cultureicon4.png";
import cultureicon5 from "../img/cultureicon5.png";
import cultureicon6 from "../img/cultureicon6.png";
import law from "../img/law.png";
import mp from "../img/mp.png";
import emp from "../img/emp.png";
import stasoul from "../img/stasoul.png";
import assessment from "../img/assessment.png";
import values from "../img/vision.png";
import mission from "../img/mission.png";
import close from "../img/close.png";
// Add the team media files
import team_ex from "../img/teams_ex.png";
import team_tm from "../img/teams_tm.png";
import team_sy from "../img/teams_sy.png";
import team_lo from "../img/teams_lo.png";
import googleIcon from "../img/google_icon.svg";
import facebookIcon from "../img/facebook_icon.svg";
import twitterIcon from "../img/twitter_icon.svg";
// Add the Stats media files
import take from "../img/take.png";
import year from "../img/2020.png";
import con from "../img/conContain.png";
import design from "../img/design.png";
import simple from "../img/simple.png";
import phone from "../img/phone.png";
import location from "../img/location.png";
import mail from "../img/mail.png";
import bars from "../img/bars.png";
import colored from "../img/colored.png";
const Images = {
  logo,
  background,
  background1,
  background2,
  Group1,
  Group2,
  Highlight,
  menu,
  facebook,
  Google,
  X,
  backB,
  trybe1,
  trybe2,
  trybe3,
  seyi,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  // Testimonial logos
  googleLogo,
  walmartLogo,
  paypalLogo,
  mondayLogo,
  logitechLogo,
  // Directional Buttons
  forwardButton,
  backButton,
  cultureicon1,
  cultureicon2,
  cultureicon3,
  cultureicon4,
  cultureicon5,
  cultureicon6,
  law,
  mp,
  emp,
  stasoul,
  assessment,
  values,
  mission,
  close,
  // Team Members Image
  team_ex,
  team_lo,
  team_tm,
  team_sy,
  // Social Icons
  facebookIcon,
  twitterIcon,
  googleIcon,
  take,
  year,
  con,
  design,
  simple,
  phone,
  location,
  mail,
  bars,
  colored,
};

export default Images;

import React, { useState, useEffect, useRef } from "react";
import "../css/testimonials.css";
import "../css/team.css";

import "../css/globalStyles.css";
import images from "../components/images";
import LinkButton from "../components/LinkButton";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import TeamSlider from "./Slider";
import Slider from "react-slick";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // import a loader

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4  ,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const teamItems = [
  {
    firstname: "Lolade",
    lastname: "Olukayode",
    role: "Senior Backend Developer",
    imgSrc: images.team_lo,
  },
  {
    firstname: "Seyi",
    lastname: "Akinso",
    role: "Chief Executive Officer",
    imgSrc: images.team_sy,
  },
  {
    firstname: "Timi",
    lastname: "Akinsorotan",
    role: "Senior Backend Developer",
    imgSrc: images.team_tm,
  },
  {
    firstname: "Excellence",
    lastname: "Jephter",
    role: "Senior Frontend Developer",
    imgSrc: images.team_ex,
  },
];
function Teams() {
  // const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);

  const arrowRef = useRef(null);
  let clientDisc = "";
  clientDisc = teamItems.map((item, i) => <TeamsSlider items={item} key={i} />);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
 
  return (
    <div
      className={`welcome ${mounted ? "slide-in" : "slide-out"}`}
      style={{ width: "100%" }}
    >
      <div className="innerrC team">
        <div className="innerrTop">
          <div className="backNav">
            <LinkButton to="-1" className="">
              <img alt="back button" width={40} src={images.backB} />
            </LinkButton>

          </div>
          <div className="innerrTopRight">
            <h1 className="welcomeh1">
              Our{" "}
              <span className="italic">
                Team
                <span style={{ color: "red" }}>.</span>
              </span>
            </h1>
            <p className="headSubtext">
              TheTrybeCo Consists Of Experienced Experts In Their Various
              Fields.
            </p>
          </div>
        </div>
       
        <div className="teamDirectionButtons">
          <i>
            <img
              onClick={() => arrowRef.current.slickPrev()}
              alt="backward button"
              width={30}
              src={images.backButton}
            />
          </i>
          <i>
            <img
              onClick={() => arrowRef.current.slickNext()}
              alt="forward button"
              width={30}
              src={images.forwardButton}
            />
          </i>
        </div>
        <div className="testimonialCardSection" id="firstCard">
          <Slider ref={arrowRef} {...settings}>
            {clientDisc}
          </Slider>
          
        </div>
      </div>
    </div>
  );
}

export default Teams;

function TeamsSlider({ items }) {
  const { firstname, lastname, role, imgSrc } = items;
  return (
    <>
      <div style={{}} className="teamCard">
        {/* <div className="testimonialContent"> */}
        <div style={{ fontFamily: "poppinsBold" }} className="teamDetails">
          <p className="teamName">{`${firstname} ${lastname}`}</p>
          <p className="teamRole">{role} </p>
        </div>
        <div className="socialLinks">
          <a href="">
            <img src={images.googleIcon} alt="google handle" />
          </a>
          <a href="">
            <img src={images.facebookIcon} alt="facebook handle" />
          </a>
          <a href="">
            <img src={images.twitterIcon} alt="twitter handle" />
          </a>
        </div>
        <div className="teamImage">
          <img src={imgSrc} alt={firstname} />
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import "../css/Process.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
import linearrow from "../img/linearrow.png";
import arrowright from "../img/arrow-right.png";
import arrowleft from "../img/arrowleft.png";
import one1 from "../img/one1.png";
import two2 from "../img/two2.png";
import three3 from "../img/three3.png";
import four4 from "../img/four4.png";
// import { motion } from "framer-motion";
function Process() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
        <div className="innerrC">
            <div className='innerrTop'>
            <Link to="/Culture">
                <img alt='back button' className='backbutton' src={images.backB}/>
            </Link>
            <div className='innerrTopRight'>
                <h1 className='aboutH1'>Our <span className="aboutItalic">Process</span><span style={{ color: 'red' }}>.</span></h1>
                <p className="headSubtext">We begin every engagement the same way: by asking ‘why’ until we understand</p>
            </div>
            </div>
            <div className="processmainnn">
                        <div className="processmain">
                            <div className="hugxhug">
                                <div className="hug">
                                    <div className="iconNumber">
                                        <img alt='' src={one1}/>
                                        <h1>1</h1>
                                    </div>
                                    <p>
                                        The pain points we need to target
                                    </p>
                                </div>
                                <div className="hug">
                                    <div className="iconNumber">
                                        <img alt='' src={two2}/>
                                        <h1>3</h1>
                                    </div>
                                    <p>
                                        The goal we need to achieve 
                                    </p>
                                
                                </div>
                            </div>
                            <div className="liness">
                                <img src = {arrowright}className="liness1" alt="line1" />
                                <img src = {linearrow}className="liness2"  alt="line2"/>
                                <img src = {arrowleft}className="liness3"  alt="line3"/>
                            </div>
                            <div className="hugxhug">
                                <div className="hug">
                                    <div className="iconNumber">
                                        <img alt='' src={four4}/>
                                        <h1>2</h1>
                                    </div>
                                    <p>
                                        The time we have to complete it
                                    </p>
                                    
                                </div>
                                <div className="hug">
                                    <div className="iconNumber">
                                        <img alt='' src={three3}/>
                                        <h1>4</h1>
                                    </div>
                                    <p>
                                        The guides we are to follow
                                    </p>
                                </div>
                            </div>
                        </div>

            </div>
            

            <div className="container">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}>

                </div>
              <Link to="/Service" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Process;

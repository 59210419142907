const FaqData = [
    {
        question: "What Services Do You Offer?",
        answer: "We offer a wide range of services, including, branding, UI/UX design, motion graphics, web development and digital marketing. Our expertise also extends to mobile app development, ensuring a comprehensive solution for all your digital needs."
      },
      {
        question: "How is your pricing plan?",
        answer: "We offer a wide range of services, including, branding, UI/UX design, motion graphics, web development and digital marketing. Our expertise also extends to mobile app development, ensuring a comprehensive solution for all your digital needs."
      },
      {
        question: "How is your pricing plan?",
        answer: "We offer a wide range of services, including, branding, UI/UX design, motion graphics, web development and digital marketing. Our expertise also extends to mobile app development, ensuring a comprehensive solution for all your digital needs."
      },
      {
        question: "How is your pricing plan?",
        answer: "We offer a wide range of services, including, branding, UI/UX design, motion graphics, web development and digital marketing. Our expertise also extends to mobile app development, ensuring a comprehensive solution for all your digital needs."
      },
      {
        question: "How do you start a project?",
        answer: "We offer a wide range of services, including, branding, UI/UX design, motion graphics, web development and digital marketing. Our expertise also extends to mobile app development, ensuring a comprehensive solution for all your digital needs."
  },
  
  
];

export default FaqData;
import React, { useState, useEffect } from "react";
import "../css/stats.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Stats() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };
  const anii = { hidden: { opacity: 0 }, show: { opacity: 1 } };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <div className="innerrC">
        <div className="innerrTop">
          <Link to="/Projects">
            <img alt="back button" class="backbutton" src={images.backB} />
          </Link>
          <div className="innerrTopRight">
            <h1 className="aboutH1">
              Stats & <span className="aboutItalic">Extras</span>
              <span style={{ color: "red" }}>.</span>
            </h1>
            <p className="headSubtext">
              We’re glad you’ve journeyed so far. check these out!
            </p>
          </div>
        </div>
        <motion.div className="stat">
          <div className="statLeft">
            <div>
              <h1>10+</h1>
              <p>TrybezMen</p>
            </div>
            <div>
              <h1>5+</h1>
              <p>Years Of Expertise</p>
            </div>
            <div>
              <h1>5+</h1>
              <p>Awards Given</p>
            </div>
          </div>
          <div className="statCenter">
            <div className="CenterTop">
              <div className="topLeft">
                <div className="topLeftTop">
                  <img
                    className="firstImage"
                    alt="TheTrybeCo"
                    src={images.design}
                  />
                  <img
                    className="secondImage"
                    alt="TheTrybeCo"
                    src={images.year}
                  />
                </div>
                <div className="topLeftBottom">
                  <img
                    className="firstImage"
                    alt="TheTrybeCo"
                    src={images.simple}
                  />
                  <img
                    className="secondImage"
                    alt="TheTrybeCo"
                    src={images.take}
                  />
                </div>
              </div>
              <div className="topRight">
                <img className="thirdImage" alt="TheTrybeCo" src={images.con} />
                <div className="conDiv">
                  <p className="conH1">Contact Information</p>
                  <div className="conDivmain">
                    <div className="conDiv1">
                      
                      <img className="conImg" src={images.phone} />
                      <p className="conP">+234 (0) 805 570 3002</p>
                      <p className="conP">+234 (0) 906 855 8280</p>
                      <p className="conP">+234 (0) 810 009 0758</p>
                    </div>
                    
                    <div className="conDiv2">
                      <div className="conDivFlex">
                        <img className="conImg" src={images.mail} />
                        <a href="mailto:hello@thetrybeco.org" className="conP">
                          hello@thetrybeco.org
                        </a>
                      </div>
                      <div className="conDivFlex">
                        <img className="conImg" src={images.location} />
                        <a target="_blank" href="https://maps.app.goo.gl/mGmnNfexGNRfJbb5A" className="conP">See On Google Map!</a>
                      </div>
                    </div>
                  </div>
                
                  
                  
                </div>
              </div>
            </div>
            <div className="CenterBottom">
              <img alt="Thetrybeco logo" src={images.logo} />
              <p>Teamwork,</p>
              <p>Ingenuity,</p>
              <p>Excellence</p>
            </div>
          </div>
          <div className="statLeft">
            <div>
              <h1>40+</h1>
              <p>Trainees</p>
            </div>
            <div>
              <h1>5+</h1>
              <p>Featured Projects</p>
            </div>
            <div>
              <h1>5+</h1>
              <p>Happy Clients</p>
            </div>
          </div>
        </motion.div>
        <div className="gateContainer">
          <div className="container">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}
              ></div>
              <Link to="/Team" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;

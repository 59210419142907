import React, { useState, useEffect } from 'react';
import '../css/globalStyles.css';
import '../css/gallery.css';
import images from '../components/images';
import { Link } from'react-router-dom';
import gallery1 from '../img/gallery91.jpg';
import gallery2 from '../img/gallery2.png';
import gallery3 from '../img/gallery3.png';
import gallery4 from '../img/gallery88.jpg';
import gallery5 from '../img/gallery5.png';
import gallery6 from '../img/gallery6.png';
import gallery7 from '../img/gallery7.png';
import gallery8 from '../img/ga.jpg';
import gallery9 from '../img/gallery9.png';
import gallery10 from '../img/gallery10.png';
import gallery11 from '../img/gallery11.png';
import { motion } from "framer-motion";
function Gallery() {
  const ani = { hidden: { opacity: 0 }, show: { opacity: 1 } };
  

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
    const toggleCircle3 = () => {
      setIsCircleActive3(!isCircleActive3);
    };
  return (
    <div className={`gallery ${mounted ? "slide-in" : "slide-out"}`}>
      <Link to="/Welcome" className="bckbtn">
        <img alt="back button" width={40} src={images.backB} />
        
      </Link>
      <div className="whole">
        <div className="container6">
          <div className="picture">
            <div className="imgclass">
              <img src={gallery1} alt="gallery91" className='img01'/>
              <div className="content-gallery"> </div>
            </div>

            <div className="imgclass">
              <img src={gallery2} alt="gallery2" />
              <div className="content-gallery"> </div>
            </div>
          </div>

          <div className="picture1">
            <div className="imgclass">
              <img src={gallery3} alt="gallery3" />
              <div className="content-gallery"> </div>
            </div>

            <div className="imgclass5">
              <img src={gallery4} alt="gallery4" className='img04'/>
              <div className="content-gallery"> </div>
            </div>
          </div>

          <div className="picture2">
            <div className="imgclass">
              <img src={gallery5} alt="gallery5" />
              <div className="content-gallery">
                {" "}
                <p></p>{" "}
              </div>
            </div>

            <div className="imgclass6">
              <img src={gallery6} alt="gallery6" />
              <div className="content-gallery"> </div>
            </div>
          </div>

          <div className="picture">
            <div className="imgclass7">
              <img src={gallery7} alt="gallery7" />
              <div className="content-gallery"> </div>
            </div>

            <div className="imgclass8">
              <img src={gallery8} alt="gallery8" className='img06'/>
              <div className="content-gallery"> </div>
            </div>
          </div>

          <div className="picture8">
            <div className="imgclass">
              <img src={gallery9} alt="gallery9" />
              <div className="content-gallery"> </div>
            </div>

            <div className="imgclass">
              <img src={gallery10} alt="gallery10" />
              <div className="content-gallery"> </div>
            </div>

            <div className="imgclass">
              <img src={gallery11} alt="gallery11" />
              <div className="content-gallery"> </div>
            </div>
          </div>
        </div>
        <div className="texttt">
        <motion.div variants={ani} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 1, ease: "easeIn", delay: 0.5}}>
          <div className="textis">
            <h1>TEAMWORK</h1>
          </div>
          
       </motion.div>
       <motion.div variants={ani} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 1, ease: "easeIn", delay: 1.5}} >
          <div className="textis1">
            <h1>INGENUITY</h1>
          </div>
          
       </motion.div>
          
       <motion.div variants={ani} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 1, ease: "easeIn", delay: 2.5}} >
          <div className="textis2">
            <h1>EXCELLENCE</h1>
          </div>
          
       </motion.div>
          
        </div>
      </div>
      <div className="container4">
        <div
          className="joinBtnn"
          onMouseEnter={toggleCircle3}
          onMouseLeave={toggleCircle3}
        >
          <div
            className={`circleLong ${isCircleActive3 ? "active" : ""}`}
          ></div>
          <Link to="/Testimonials" style={{ textDecoration: "none" }}>
            <p className="btnOpenForm">Continue Your Journey</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Gallery;

import React, { useState, useEffect } from "react";
import "../css/values.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function Values() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <div className="innerrC">
        <div className="innerrTop">
          <Link to="/Aboutt">
            <img alt="back button" className="backbutton" src={images.backB} />
          </Link>
          <div className="innerrTopRight">
            <h1 className="aboutH1">
              Our Core <span className="aboutItalic">Values</span>
              <span style={{ color: "red" }}>.</span>
            </h1>
          </div>
        </div>
        <motion.div className="valueContainer">
          <div className="values">
            <p>
              As a Trybe of passionate professionals, we excel in the realm of
              design, development & photography. Our team is driven by a shared
              mission to make work meaningful, striving to infuse each project
              with purpose, creativity, and innovation.
            </p>
          </div>
          <div className="twin">
            <div className="single">
              <div className="valuesTop">
                <h2>
                  Our <span className="aboutItalic">Vision</span>
                  <span style={{ color: "red" }}>.</span>
                </h2>
                <img alt="thetrybe values" src={images.values} />
              </div>
              <p>
                In 2008, Seyi had a realization: everything around us is ugly.
                We need to make it look better. With that ambitious mission in
                mind, TheTrybeCo was born.
              </p>
            </div>
            <div className="single">
              <div className="valuesTop">
                <h2>
                  Our <span className="aboutItalic">Mission</span>
                  <span style={{ color: "red" }}>.</span>
                </h2>
                <img alt="thetrybe values" src={images.mission} />
              </div>
              <p>
                In 2008, Seyi had a realization: everything around us is ugly.
                We need to make it look better. With that ambitious mission in
                mind, TheTrybeCo was born.
              </p>
            </div>
          </div>
        </motion.div>
        <div className="gateContainer">
          <div className="containerr">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}
              ></div>
              <Link to="/Culture" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Values;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import images from "./images";
import "../css/navbar.css";

const Navbar = ({ isOpen, toggleNavbar }) => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
  
    const handleLinkClick = (path) => {
      setActiveLink(path);
      toggleNavbar(); // Close the navbar on link click
    };
  
  const links = [
    { path: "/", label: "Home To All Tourists", image: "back.png", tag: "The Gates" },
    { path: "/Aboutt", label: "Who We Are", image: "bacc.png", tag: "Trybe Phase 1" },
    { path: "/Values", label: "What We Stand For", image: "timi.png", tag: "Trybe Phase 2" },
    { path: "/Team", label: "Our Team", image: "xl.png", tag: "Trybe Phase 3" },
    { path: "/Contact", label: "Contact Us", image: "lola.png", tag: "Trybe Phase 4" },
  ];

  

  return (
    <div className={`sideNav ${isOpen ? 'open' : ''}`}>
      <div className="sideNavTop">
        <p>Explore TheTrybeCo</p>
        <img alt="close" src={images.close} onClick={toggleNavbar} />
      </div>
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.path}
          className={`sideNavBottom ${activeLink === link.path ? 'active' : ''}`}
          onClick={() => handleLinkClick(link.path)}
        >
          <p>{link.tag}</p>
          <h2>{link.label}</h2>
        </Link>
      ))}
    </div>
  );
};

export default Navbar;

import React, { useState } from "react";
import "./App.css";
import "./css/globalStyles.css";
import images from "./components/images";
import Clock from "./components/Clock";
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import Aboutt from "./pages/Aboutt";
import Testimonials from "./pages/Testimonials";
import Podcast from "./pages/podcast";
import Gallery from "./pages/gallery";
import FAQ from "./pages/FAQ";
import Culture from "./pages/Culture";
import Values from "./pages/Values";
import Projects from "./pages/Projects";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { motion } from "framer-motion";
import Contact from "./pages/Contact";
import Process from "./pages/Process";
import Navbar from "./components/Navbar";
import Stats from "./pages/Stats";


import Servicee from "./pages/Servicee";

import Teams from "./pages/Team";


function App() {
  const { dateTime, year } = Clock();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isCircleActive, setIsCircleActive] = useState(false);
  const [isCircleActive2, setIsCircleActive2] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const toggleCircle = () => {
    setIsCircleActive(!isCircleActive);
  };
  const toggleCircle2 = () => {
    setIsCircleActive2(!isCircleActive2);
  };

  const varr = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { staggerChildren: 10 } },
  };
  const ani = { hidden: { opacity: 0 }, show: { opacity: 1 } };
  const anii = { hidden: { opacity: 0 }, show: { opacity: 1 } };

  return (
    <Router>
      <motion.div
        variants={varr}
        initial="hidden"
        animate="show"
        transition={{ delay: 30, duration: 30 }}
        className="App"
      >
        <motion.nav variants={ani}>
          <Link to="/">
            <motion.img
              variants={anii}
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 4.5 }}
              alt="Thetrybeco"
              width={95}
              src={images.logo}
              class="logo"
            />
          </Link>
          <motion.div
            variants={anii}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut", delay: 4.5 }}
            class="navRight"
          >
            <div
              class="containerNav"
              onMouseEnter={toggleCircle2}
              onMouseLeave={toggleCircle2}
              onClick={toggleNavbar}
            >
              <div className={`circleNav ${isCircleActive2 ? "active" : ""}`}>
                <img alt="Thetrybeco" className="logo1" src={images.menu} />
              </div>
              <p class="clock">Explore TheTrybe</p>
            </div>
            <div
              id="container"
              onMouseEnter={toggleCircle}
              onMouseLeave={toggleCircle}
            >
              <div className={`circle ${isCircleActive ? "active" : ""}`}></div>
              <p id="clock">{dateTime}</p>
            </div>
          </motion.div>
        </motion.nav>
        <Navbar isOpen={isNavbarOpen} toggleNavbar={toggleNavbar} />
        
        {/* The Hero Section */}
        <div class="main">
          <motion.div
            variants={ani}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, ease: "easeIn", delay: 5.5 }}
            className="bodie"
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="Welcome" element={<Welcome />} />
              <Route path="Gallery" element={<Gallery />} />
              <Route path="Testimonials" element={<Testimonials />} />
              <Route path="FAQ" element={<FAQ />} />
              <Route path="Aboutt" element={<Aboutt />} />
              <Route path="Culture" element={<Culture />} />
              <Route path="Values" element={<Values />} />
              <Route path="Process" element={<Process/>} />
              <Route path="Projects" element={<Projects />} />
              <Route path="Service" element={<Servicee />} />
              <Route path="Podcast" element={<Podcast />} />
              <Route path="Contact" element={<Contact />} />
              <Route path="Stats" element={<Stats />} />
              {/* Add Teams Routing */}
              <Route path="Team" element={<Teams />} />
            </Routes>
          </motion.div>
        </div>
        {/* THE BOTTOM */}
        <motion.div
          variants={anii}
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 4.5 }}
          class="bottom"
        >
          <div class="imgContain">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/thetrybeco/"
            >
              <img alt="Thetrybeco" class="image p" src={images.Google} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/thetrybeco/"
            >
              <img alt="Thetrybeco" class="image p" src={images.X} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/thetrybeco?s=20"
            >
              <img alt="Thetrybeco" class="image p" src={images.facebook} />
            </a>
          </div>
          <p style={{ fontSize: "14px", zindex: 10 }}>
            {" "}
            By
            <a
              style={{
                color: "#fff",
                textdecoration: "underline",
                marginLeft: "5px",
              }}
              href="https://www.linkedin.com/in/excellence-jephter-22382317a"
              target="_blank"
              rel="noreferrer"
            >
              TheTrybeCo{" "}
            </a>
          </p>
          <p>
            <button id="year" class="btn">
              {year}
            </button>
          </p>
        </motion.div>
      </motion.div>
    </Router>
  );
}

export default App;

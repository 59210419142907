import React from "react";
// import styled from "styled-components";

const testimonialCardStyle = {
  border: "1px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "30px",
  //   maxWidth: "400px",
  //   minWidth: "450px",
  width: "100%",
//   height: "220px",
  background:
    "linear-gradient(to right, rgba(39, 39, 39, 1), rgba(88, 88, 88, 0.96))",
  padding: "18px 20px 40px 24px",
  boxSizing: "border-box",
  overflow: "hidden",
};
function TestimonialSlider({
  maxRating = 5,
  color = "#FBB11C",
  size = 25,
  items,
}) {
  const { firstname, lastname, desc, year } = items;
  return (
    <>
      <div style={testimonialCardStyle} className="testimonialCard">
        <div style={{ display: "flex" }} className="starRating">
          {Array.from({ length: maxRating }, (_, i) => (
            <Star key={i} color={color} size={size} />
          ))}
        </div>
        <div className="testimonialContent">
          <div>
            <p>{desc}</p>
          </div>
          <div className="testimonialClientDetails">
            <p className="name">
              {firstname}{" "}
              <span style={{ fontFamily: "poppinsBold" }}>{lastname}</span>
            </p>
            <p className="year">
              Client - <span style={{ fontFamily: "poppinsBold" }}>{year}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialSlider;

function Star({ color, size }) {
  const starStyle = {
    width: `${size}px`,
    height: `${size}px`,
    display: "block",
  };

  return (
    <span style={starStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={color}
        stroke={color}
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    </span>
  );
}
/* <div style={testimonialCardStyle} className="testimonialCard">
<div style={{ display: "flex" }} className="starRating">
  {Array.from({ length: maxRating }, (_, i) => (
    <Star key={i} color={color} size={size} />
  ))}
</div>
<div className="testimonialContent">
  <div>
    <p>
      TheTrybeCo has created both a brand identity and a new website
      that I could not be happier with. In the beginning, Jordan took
      the time to understand my business and the objectives of both the
      new branding and the website.
    </p>
  </div>
  <div className="testimonialClientDetails">
    <p className="name">
      David <span style={{ fontFamily: "poppinsBold" }}>Orobosa</span>
    </p>
    <p className="year">
      Client - <span style={{ fontFamily: "poppinsBold" }}>2023</span>
    </p>
  </div>
</div>
</div>
<div style={testimonialCardStyle} className="testimonialCard">
<div style={{ display: "flex" }} className="starRating">
  {Array.from({ length: maxRating }, (_, i) => (
    <Star key={i} color={color} size={size} />
  ))}
</div>
<div className="testimonialContent">
  <div>
    <p>
      TheTrybeCo was highly professional, straightforward and delivered
      our website in good time and to a high standard.
    </p>
  </div>
  <div className="testimonialClientDetails">
    <p className="name">
      Umar Balogun{" "}
      <span style={{ fontFamily: "poppinsBold" }}>Olalekan</span>
    </p>
    <p className="year">
      Client - <span style={{ fontFamily: "poppinsBold" }}>2023</span>
    </p>
  </div>
</div>
</div>
<div
style={testimonialCardStyle}
className="testimonialCard"
id="lastCard"
>
<div style={{ display: "flex" }} className="starRating">
  {Array.from({ length: maxRating }, (_, i) => (
    <Star key={i} color={color} size={size} />
  ))}
</div>
<div className="testimonialContent">
  <div>
    <p>
      Delighted with the service we have received- always quick,
      efficient and extremely helpful. Would definitely recommend
      TheTrybeCo.
    </p>
  </div>
  <div className="testimonialClientDetails">
    <p className="name">
      Excellence{" "}
      <span style={{ fontFamily: "poppinsBold" }}>Jephter</span>
    </p>
    <p className="year">
      Client - <span style={{ fontFamily: "poppinsBold" }}>2023</span>
    </p>
  </div>
</div>
</div>
<div style={testimonialCardStyle} className="testimonialCard">
<div style={{ display: "flex" }} className="starRating">
  {Array.from({ length: maxRating }, (_, i) => (
    <Star key={i} color={color} size={size} />
  ))}
</div>
<div className="testimonialContent">
  <div>
    <p>
      TheTrybeCo was highly professional, straightforward and delivered
      our website in good time and to a high standard.
    </p>
  </div>
  <div className="testimonialClientDetails">
    <p className="name">
      Umar Balogun{" "}
      <span style={{ fontFamily: "poppinsBold" }}>Olalekan</span>
    </p>
    <p className="year">
      Client - <span style={{ fontFamily: "poppinsBold" }}>2023</span>
    </p>
  </div>
</div>
</div> */

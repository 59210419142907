import React, { useState, useEffect, useRef } from "react";
import "../css/testimonials.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
import LinkButton from "../components/LinkButton";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialSlider from "./Slider";
import Slider from "react-slick";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // import a loader

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.5,
  initialSlide: 0,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const items = [
  {
    desc: "TheTrybeCo has created both a brand identity and a new website that I could not be happier with. In the beginning, Jordan took the time to understand my business and the objectives of both the new branding and the website.",
    firstname: "David",
    lastname: "Daniel",
    year: "2023",
  },
  {
    desc: "TheTrybeCo has created both a brand identity and a new website that I could not be happier with. In the beginning, Jordan took the time to understand my business.",
    firstname: "Samuel",
    lastname: "Mark",
    year: "2023",
  },
  {
    desc: " TheTrybeCo has created both a brand identity and a new website that I could not be happier with. In the beginning, Jordan took the time to understand my business and the objectives of both the new branding and the website.",
    firstname: "David",
    lastname: "Orobosa",
    year: "2023",
  },
  {
    desc: "In the beginning, Jordan took the time to understand my business and the objectives of both the new branding and the website.",
    firstname: "Meru",
    lastname: "Clifford",
    year: "2024",
  },
];
function Testimonials() {
  // const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);

  const arrowRef = useRef(null);
  let clientDisc = "";
  clientDisc = items.map((item, i) => (
    <TestimonialSlider items={item} key={i} />
  ));

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };

  //   const images = [
  //     {
  //       id: "102",
  //       author: "Ben Moore",
  //       width: 4320,
  //       height: 3240,
  //       url: "https://unsplash.com/photos/pJILiyPdrXI",
  //       download_url: "https://picsum.photos/id/102/4320/3240",
  //     },
  //     {
  //       id: "103",
  //       author: "Ilham Rahmansyah",
  //       width: 2592,
  //       height: 1936,
  //       url: "https://unsplash.com/photos/DwTZwZYi9Ww",
  //       download_url: "https://picsum.photos/id/103/2592/1936",
  //     },
  //     {
  //       id: "104",
  //       author: "Dyaa Eldin",
  //       width: 3840,
  //       height: 2160,
  //       url: "https://unsplash.com/photos/2fl-ocJ5MOA",
  //       download_url: "https://picsum.photos/id/104/3840/2160",
  //     },
  //     {
  //       id: "106",
  //       author: "Arvee Marie",
  //       width: 2592,
  //       height: 1728,
  //       url: "https://unsplash.com/photos/YnfGtpt2gf4",
  //       download_url: "https://picsum.photos/id/106/2592/1728",
  //     },
  //     {
  //       id: "107",
  //       author: "Lukas Schweizer",
  //       width: 5000,
  //       height: 3333,
  //       url: "https://unsplash.com/photos/9VWOr22LhVI",
  //       download_url: "https://picsum.photos/id/107/5000/3333",
  //     },
  //   ];
  // const [currentPage, setCurrentPage] = useState(0);

  // const totalPages = Math.ceil(items.length / 3);

  // const nextSlide = () => {
  //   setCurrentPage((prevPage) =>
  //     prevPage === totalPages - 1 ? 0 : prevPage + 1
  //   );
  // };

  // const prevSlide = () => {
  //   setCurrentPage((prevPage) =>
  //     prevPage === 0 ? totalPages - 1 : prevPage - 1
  //   );
  // };

  // const startIndex = currentPage * 3;
  // const endIndex = Math.min(startIndex + 3, items.length);
  // const visibleItems = items.slice(startIndex, endIndex);
  return (
    <div
      className={`welcome ${mounted ? "slide-in" : "slide-out"}`}
      style={{ width: "100%" }}
    >
      <div className="innerrT">
        <div className="innerrTop">
          <LinkButton to="/Gallery">
            <img alt="back button" width={40} src={images.backB} />
          </LinkButton>
          <div className="innerrTopRight">
            <h1 className="welcomeH1">
              What Clients{" "}
              <span className="italic">
                Say
                <span style={{ color: "red" }}>.</span>
              </span>
            </h1>
            <p className="headSubtext">
              Take a little rest here and read what people say about our Trybe.
            </p>
          </div>
        </div>
        <div className="testimonialDirectionButtons">
          <i>
            <img
              onClick={() => arrowRef.current.slickPrev()}
              alt="backward button"
              width={40}
              src={images.backButton}
            />
          </i>
          <i>
            <img
              onClick={() => arrowRef.current.slickNext()}
              alt="forward button"
              width={40}
              src={images.forwardButton}
            />
          </i>
        </div>
        <div className="testimonialCardSection" id="firstCard">
          <Slider ref={arrowRef} {...settings}>
            {clientDisc}
          </Slider>
        </div>

        <div className="testimonialLogos">
          <img alt="logitech logo" src={images.logitechLogo} />
          <img alt="paypal logo" src={images.paypalLogo} />
          <img alt="monday logo" src={images.mondayLogo} />
          <img alt="google logo"  src={images.googleLogo} />
          <img alt="walmart logo" src={images.walmartLogo} />
        </div>
        <div className="container" style={{ marginLeft: 0 }}>
          <div
            className="joinBtnn"
            onMouseEnter={toggleCircle3}
            onMouseLeave={toggleCircle3}
          >
            <div
              className={`circleLong ${isCircleActive3 ? "active" : ""}`}
            ></div>
            <Link to="/FAQ" style={{ textDecoration: "none" }}>
              <p className="btnOpenForm">Continue Your Journey</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

// function Star({ color, size }) {
//   const starStyle = {
//     width: `${size}px`,
//     height: `${size}px`,
//     display: "block",
//   };

//   return (
//     <span style={starStyle}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill={color}
//         stroke={color}
//       >
//         <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//       </svg>
//     </span>
//   );
// }

export default Testimonials;

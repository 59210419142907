import React, { useState, useEffect } from 'react';
import '../css/globalStyles.css';
import '../css/culture.css';
import images from '../components/images';
import { Link } from 'react-router-dom';
import { motion, } from "framer-motion";

function Culture() {
    const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);


  const [isCircleActive3, setIsCircleActive3] = useState(false);
    const toggleCircle3 = () => {
      setIsCircleActive3(!isCircleActive3);
    };

  return (
    <div className={`welcome ${mounted ? 'slide-in' : 'slide-out'}`}>
        <div className="innerrC">
        <div className='innerrTop'>
          <Link to="/Values">
            <img alt='back button' className='backbutton' src={images.backB}/>
          </Link>
          <div className='innerrTopRight'>
            <h1 className='aboutH1'>Our <span className="aboutItalic">Culture</span><span style={{ color: 'red' }}>.</span></h1>
            <p className="headSubtext">Our shared culture keeps us connected and guided us as one</p>
          </div>
        </div>
        <motion.div className='cultureContainer'>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon1}/>
            <h3>Extremely Client-Centric</h3>
            <p>At our core, we’re an extension of our client’s teams, If our clients are not 100% satisfied, our job is far from over.</p>
          </div>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon2}/>
            <h3>Believe in the power of us</h3>
            <p>“The whole is greater than the sum of its parts”.Our team unlocks creative solutions that individuals simply cannot create alone.</p>
          </div>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon3}/>
            <h3>We question certainties</h3>
            <p>The mindset of intellectual bravery pushes us to continuously question the status quo. We welcome innovative.</p>
          </div>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon4}/>
            <h3>We Pursue Excellence</h3>
            <p>For us, excellence is the absolute minimum baseline. Our team strives to raise the bar surpassing past achievements.</p>
          </div>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon5}/>
            <h3>We pay it forward</h3>
            <p>We measure our company’s health not by profits, but by the positive impacts we have generated. We believe in the power of generosity</p>
          </div>
          <div className='cultureBlock'>
            <img alt='TheTrybeCo icon' src={images.cultureicon6}/>
            <h3>Sweat the small stuff</h3>
            <p>We believe that true excellence lives in the details. It is in granular analysis that we find opportunities others overlook.</p>
          </div>
        </motion.div>
        <div className='gateContainer'>        
        <div className="containerr">
          <div className="joinBtnn" onMouseEnter={toggleCircle3} onMouseLeave={toggleCircle3}>
            <div className={`circleLong ${isCircleActive3 ? 'active' : ''}`}></div>
            <Link to="/Process" style={{ textDecoration: 'none' }} >
              <p className="btnOpenForm">Continue Your Journey</p>
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Culture
import React, { useState, useRef } from 'react';
import ReactCardFlip from 'react-card-flip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MultipleCards.css'; 
import { Link } from'react-router-dom';
import { NextArrow, PrevArrow } from './navbutton';
import  card1  from "./multiplecardimg/card1.png";
import  card2  from "./multiplecardimg/card2.png";
import  card3  from "./multiplecardimg/card3.png";

const Card = ({ id, isFlipped, handleClick, frontContent, backContent, frontClassName, backClassName }) => {
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" className="cardFlip">
      <div key="front" className={`card card-front ${frontClassName}`} onClick={() => handleClick(id)}>
        {frontContent}
      </div>

      <div key="back" className={`card card-back ${backClassName}`} onClick={() => handleClick(id)}>
        {backContent}
      </div>
    </ReactCardFlip>
  );
};

const MultipleCardsSlider = () => {
  const [flippedCards, setFlippedCards] = useState({});
  const sliderRef = useRef(null);

  const cards = [
    { id: 1, front: '', back: 
    <div className='cardBackMain'>
        <div className='cardHeader'>
          <img src= {card1} alt=''/>
          <h1><strong>Application Development</strong></h1>
        </div>
        <p>
          TheTrybe uses the most advanced and cutting-edge  technologies, such as Swift, React Native, Android, Flutter, and more, to build app solutions that are fast, secure, and scalable. We provide app design, app testing, app maintenance, and app marketing services.
        </p>
        <p>
          No matter what kind of app you need, whether it is for your startup, a business productivity app, a social networking app, or an e-commerce app, we can help you achieve your app success. <span>We partner with you to understand your needs, budget, and offer an app solution that exceeds your expectations.</span>
        </p>
        <div className='fp'>
          <p>Featured Projects:</p>
          <div className='fpbtns'>
            <button className="fpbtn">TheTrybe Assessment System</button>
            <button className="fpbtn">Project 19 <span>&#40;incoming&#41;</span></button>
          </div>
        </div>
        <div className="buttommm">
        <Link to="/Contact" style={{ textDecoration: "none" }}>
            <button className="btnOpenFormm">Get Started</button>
          </Link>
        </div>
    </div>, frontClassName: 'front-style-1', backClassName: 'back-style' },
    { id: 2, front: '', back:
    <div className='cardBackMain'>
        <div className='cardHeader'>
          <img src= {card2} alt=''/>
          <h1><strong>Web Development</strong></h1>
        </div>
        <p>
        Our developers use the latest and proven web technologies, such as HTML5, CSS3, JavaScript and more, to create responsive, secure, and scalable web solutions that meet your needs and goals. We also offer web design, web maintenance, SEO, and digital marketing services to help you reach your target audience. Whether you need a website for your small business, a web application, or an ecommerce platform for your online store, we can help you achieve it.<span>We work closely with you to understand your requirements, budget, expectations, and deliver a web solution that exceeds your expectations.</span> 
        </p>
       
        <div className='fp'>
          <p>Featured Projects:</p>
          <div className='fpbtns'>
            <button className="fpbtn">Stasoul Nig ltd</button>
            <button className="fpbtn">Emplace College</button>
            <button className="fpbtn">Mummy's Place</button>
          </div>
        </div>
        <div className="buttommm">
        <Link to="/Contact" style={{ textDecoration: "none" }}>
            <button className="btnOpenFormm">Get Started</button>
          </Link>
          
        </div>
    </div>, frontClassName: 'front-style-2', backClassName: 'back-style' },
    { id: 3, front: '', back: 
    <div className='cardBackMain'>
        <div className='cardHeader'>
          <img src= {card3} alt=''/>
          <h1><strong>Product Design</strong></h1>
        </div>
        <p>
          TheTrybe uses the most advanced and cutting-edge  technologies, such as Swift, React Native, Android, Flutter, and more, to build app solutions that are fast, secure, and scalable. We provide app design, app testing, app maintenance, and app marketing services.
        </p>
        <p>
          
          No matter what kind of app you need, whether it is for your startup, a business productivity app, a social networking app, or an e-commerce app, we can help you achieve your app success. <span>We partner with you to understand your needs, budget, and offer an app solution that exceeds your expectations.</span>
        </p>
        <div className='fp'>
          <p>Featured Projects:</p>
          <div className='fpbtns'>
            <button className="fpbtn">SaveApp</button>
            <button className="fpbtn">TheTrybe App</button>
            <button className="fpbtn">Mummy's Place</button>

          </div>
        </div>
        <div className="buttommm">
        <Link to="/Contact" style={{ textDecoration: "none" }}>
            <button className="btnOpenFormm">Get Started</button>
          </Link>
        </div>
    </div>, frontClassName: 'front-style-3', backClassName: 'back-style' },
  ];

  const handleClick = (id) => {
    setFlippedCards(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:2.5,
    centerMode: false,
    centerPadding: '0px',
    slidesToScroll: 1,
    initialSlide: 0, 
    arrows: false, 
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rtl: false,
          initialSlide: 0
        },
      },
      {
        breakpoint: 480, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rtl: false,
        },
      },
    ],
  };
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="card-container">
      <div className="BtnCtrl">
              <button className="custom-prev" onClick={previous}>
                <PrevArrow />
              </button>
              <button className="custom-next" onClick={next}>
                <NextArrow />
              </button>
      </div>
      
      <Slider ref={sliderRef}{...settings}className="slider"> 
          {cards.map((card, index) => (
          <div key={card.id} className={index === cards.length - 1 ? 'last-card' : ''}>
            <Card
              id={card.id}
              isFlipped={!!flippedCards[card.id]}
              handleClick={handleClick}
              frontContent={card.front}
              backContent={card.back}
              frontClassName={card.frontClassName}
              backClassName={card.backClassName}
            />
          </div>
        ))}
      </Slider>
     
    </div>
  );
};

export default MultipleCardsSlider;

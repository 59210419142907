import { useState, useEffect } from 'react';

function Clock() {
  const [dateTime, setDateTime] = useState(getFormattedDateTime());
  const [year, setYear] = useState(getYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(getFormattedDateTime());
      setYear(getYear());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function getFormattedDateTime() {
    const d = new Date();
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();
    let seconds = d.getSeconds();
    let AMorPM = "AM";

    if (hour >= 12) {
      AMorPM = "PM";
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[month];

    return `${day}-${monthName}-${year}, ${hour}:${min}:${seconds} ${AMorPM}`;
  }

  function getYear() {
    const d = new Date();
    return d.getFullYear();
  }

  return {
    dateTime: dateTime,
    year: year
  };
}

export default Clock;
import React, { useState, useEffect } from "react";
import "../css/projects.css";
import "../css/globalStyles.css";
import images from "../components/images";
import projectsData from "../components/projectsData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Projects() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const handleQuestionClick = (index) => {
    setSelectedQuestion(index);
  };
  const anii = { hidden: { opacity: 0 }, show: { opacity: 1 } };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <div className="innerrC">
        <div className="innerrTop">
          <Link to="/Service">
            <img alt="back button" class="backbutton" src={images.backB} />
          </Link>
          <div className="innerrTopRight">
            <h1 className="welcomeH1">
              Our Featured <span className="aboutItalic">Projects</span>
              <span style={{ color: "red" }}>.</span>
            </h1>
          </div>
        </div>
        <motion.div className="proj">
          <div className="projContainer">
            <div className="projLeft">
              {projectsData.map((faq, index) => (
                <motion.div
                  variants={anii}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, ease: "easeOut", delay: 1 }}
                  className="projQuestion"
                  key={index}
                  onClick={() => handleQuestionClick(index)}
                >
                  {faq.question}
                </motion.div>
              ))}
            </div>
            <div className="projRight">
              {selectedQuestion !== null && (
                <motion.img
                  variants={anii}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, ease: "easeOut", delay: 1.5 }}
                  className="projAnswer"
                  src={projectsData[selectedQuestion].image}
                />
              )}
              <div className="straight">
                {selectedQuestion !== null && (
                  <motion.h1
                    variants={anii}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeOut", delay: 1.5 }}
                    className="projAnswer"
                  >
                    {projectsData[selectedQuestion].answer}
                  </motion.h1>
                )}
                {selectedQuestion !== null && (
                  <motion.p
                    variants={anii}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeOut", delay: 1.5 }}
                    className="projCat"
                  >
                    {projectsData[selectedQuestion].category}
                  </motion.p>
                )}
                {selectedQuestion !== null && (
                  <Link
                    target="_blank"
                    className="link"
                    to={projectsData[selectedQuestion].link}
                  >
                    See Live Project
                  </Link>
                )}
              </div>
            </div>
          </div>
        </motion.div>
        <div className="gateContainer">
          <div className="gate">
            <span className="dis">
              <p style={{ fontSize: "16px" }}>
                There's still a lot more to see.
              </p>
            </span>
          </div>

          <div className="container">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}
              ></div>
              <Link to="/Stats" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;

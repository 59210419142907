import React, { useState, useEffect } from "react";
import "../css/welcome.css";
import "../css/globalStyles.css";
import images from "../components/images";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import fwdbtn from "../img/forwardbtn.png";

function Welcome() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <div className="innerrC">
        <div className="innerrTop">
          <Link to="/">
            <img alt="back button" class="backbutton" src={images.backB} />
          </Link>
          <div className="innerrTopRight">
            <h1 className="welcomeH1">
              Welcome to <span className="italic">TheTrybeCo</span>
              <span style={{ color: "red" }}>.</span>
            </h1>
            <p className="headSubtext">
              Here’s a little welcome note from our leader
            </p>
          </div>
        </div>
        <motion.div className="middle">
          <motion.div className="middleLeft">
            <div className="imageContained">
              <img alt="Seyi Akinso" className="seyi" src={images.seyi} />
            </div>
            <h3>Sheyi Akinso</h3>
            <p>CEO, TheTrybeCo</p>
          </motion.div>
          <motion.div className="middleCenter">
            <p>
              I am very excited to welcome you to our trybe. When we started a
              little over 4 years ago, the goal was simple, “raise a small
              community of tech savants, with a passion for technology and a
              desire to make a difference. From the moment we embarked on this
              journey, our team has been driven by a shared purpose.
              <br />
              <br /> Ours is a group of passionate individuals, who constantly
              push each other to think differently and create products that
              truly make a difference.
              <br />
              <br />
              We have grown into a reputable tech startup, garnering recognition
              for our innovative solutions, and customer-centric approach.
              <br />
              <br />
              Through teamwork, we are progressively committed to delivering
              excellence in everything we do & ensuring that your experience
              with our trybe is nothing short of exceptional. Thank you for
              coming on this incredible adventure with us, lets shape the future
              of technology & create a world where innovation knows no bounds.
              <br />
              Warm Regards,
            </p>
          </motion.div>
          <motion.div className="middleRight">
            <span className="dis">
              <p>Want to know more about us?</p>
            </span>
            <Link
              style={{ display: "flex", alignItems: "center" }}
              to="/Aboutt"
            >
              <img alt="back button" width={30} height={30} src={fwdbtn} />
              See More
            </Link>
          </motion.div>
        </motion.div>
        <div className="gateContainer">
          <div className="gate">
            <span className="dis">
              {" "}
              <p style={{ fontSize: "16px" }}>
                There's still a lot more to see.
              </p>
            </span>
          </div>

          <div className="containerr">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}
              ></div>
              <Link to="/Gallery" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;

import React, { useState, useEffect } from "react";
import "../css/faq.css";
import "../css/globalStyles.css";
import images from "../components/images";
import FaqData from "../components/FaqData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

library.add(faPlus, faMinus);
library.add(faCircle, faArrowUpRightFromSquare);
function FAQ() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const [isCircleActive3, setIsCircleActive3] = useState(false);
  const toggleCircle3 = () => {
    setIsCircleActive3(!isCircleActive3);
  };
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const handleQuestionClick = (index) => {
    setSelectedQuestion(index);
  };
  const anii = { hidden: { opacity: 0 }, show: { opacity: 1 } };

  const [isClicked, setIsClicked] = useState(false);
  const toggleFaq = (index) => {
    if (isClicked === index) {
      return setIsClicked(false);
    } else {
      setIsClicked(index);
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <div className="innerr">
        <div className="innerrTop-faq">
          <Link to="/Testimonials">
            <img alt="back button" class="backbutton" src={images.backB} />
          </Link>
        </div>
        <div className="middle">
          <div className="faqContainer">
            <div className="faqLeft">
              <h2>
                Frequently Asked <br />
                Questions<span style={{ color: "red" }}>.</span>
              </h2>
              <br />
              <p>
                We strive to provide transparency <br />
                and clarity so you have a clear understanding of <br />
                how we can help your business thrive.
              </p>
              <br />
              {/* <p>We understand that choosing a marketing agency is an important decision <br/>and we want to provide you with all the information you need to make an <br/>informed choice. If you have any further questions, please feel free to contact us.</p> */}

              <div className="button">
                <Link
                  to="/Contact"
                  style={{ textDecoration: "none" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <p className="btnOpennForm">
                    Contact
                    <FontAwesomeIcon
                      icon={isHovered ? "arrow-up-right-from-square" : "circle"}
                      className={isHovered ? "icon-arrow" : "icon-circle"}
                    />
                  </p>
                </Link>
              </div>
            </div>

            <div className="faqRight">
              {FaqData.map((item, index) => (
                <div
                  key={index}
                  className="faq"
                  onClick={() => toggleFaq(index)}
                >
                  <div className="faq-question">
                    <h2>{item.question}</h2>
                    {isClicked === index ? (
                      <button className="plus-button">
                        <FontAwesomeIcon icon="minus" color="red" />
                      </button>
                    ) : (
                      <button className="minus-button">
                        <FontAwesomeIcon icon="plus" color="" />
                      </button>
                    )}
                  </div>

                  <div
                    className={`content ${
                      isClicked === index ? "show" : "hidden"
                    }`}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="gateContainer">
          <div className="gate">
            <span className="dis">
              <p style={{ fontSize: "16px" }}>
                There's still a lot more to see.
              </p>
            </span>
          </div>

          <div className="container">
            <div
              className="joinBtnn"
              onMouseEnter={toggleCircle3}
              onMouseLeave={toggleCircle3}
            >
              <div
                className={`circleLong ${isCircleActive3 ? "active" : ""}`}
              ></div>
              <Link to="/Contact" style={{ textDecoration: "none" }}>
                <p className="btnOpenForm">Continue Your Journey</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import images from "../components/images";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import "../css/welcome.css";
import "../css/globalStyles.css";
import "../css/contact.css";


const showToast = (message, type) => {
  toast[type](message, {
    id: "custom-toast",
    className: "font-lato text-[16px] p-2",
    position: "top-center",
    duration: 5000,
  });
};

const Contact = () => {
  const [mounted, setMounted] = useState(false);

 
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  const [isCircleActive3, setIsCircleActive3] = useState(false);
    const toggleCircle3 = () => {
      setIsCircleActive3(!isCircleActive3);
    };
  const [name, setName] = useState("");
  const [business, setBusiness] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");


  const handleFormSubmit = async () => {
    if (!name || !business || !email || !phone || !budget || !message) {
      // Show error toast if any field is empty
      showToast("Please fill in all fields", "error");
      return;
    }

    try {
      await axios.post("https://trybe-contact-server.onrender.com/send-email", {
        name,
        business,
        email,
        phone,
        budget,
        message,
        
      });
      setName("");
      setBusiness("");
      setEmail("");
      setPhone("");
      setBudget("");
      setMessage("");
      showToast("Email sent successfully", "success");
    } catch (error) {
      // console.error("Error sending email:", error);
      showToast("Error sending email", "error");
    }
  };

  const handleBudgetSelect = async (selectedBudget) => {
    setBudget(selectedBudget);
    try {
      await axios.post("https://trybe-contact-server.onrender.com/how-did-you-hear", {
        budget: selectedBudget,
      });
      // showToast("Source submitted successfully", "success");
    } catch (error) {
      // console.error("Error submitting source:", error);
      // showToast("Error submitting source", "error");
    }
  };

  const handleGetInTouch = () => {
    handleFormSubmit();
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`welcome ${mounted ? "slide-in" : "slide-out"}`}>
      <Toaster/>
      {/* <img className='backgroundBar1' src={images.background2} /> */}
      <div div className="innerrC">
        <div className="innerrTop">
          <Link to="/FAQ" className="goBackLink">
            <img alt="back" className="backbutton" width={40} src={images.backB} />
          </Link>
          </div>
        <div className="conContainer">
            <div className="welcomeleft">
           <h1><b>Hey!</b></h1>
            <p><b>We would love to hear from you!</b>  <br/> <br/>Our team is on hand to answer any questions, <br/> understand your specific requirements, and provide you with tailored solutions that meet & exceed your expectations.
          </p>
          <div className="buttonn">
              <a target="_blank"
              rel="noreferrer"
              href="mailto:hello@thetrybeco.org" style={{ textDecoration: 'none' }} onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} >
              <p className="btnOpennForm">Send Us an Email</p>
            </a>
            </div>
       
        
       </div>

        <div className="conRight">
        <div className="formContainer">
          <div className="inputFields">
            <input
              type="text"
              placeholder="Your Name"
              className="inputField"
              value={name}
              onChange={(e) => setName(e.target.value)}
                />
                <input
              type="text"
              placeholder="Your Business"
              className="inputField"
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
            />
            <input
              type="email"
              placeholder="Your Email"
              className="inputField"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
                />
                <input
              type="number"
              placeholder="Your Phone"
              className="inputField"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
           
            <p className="aboutUs">Project Budget</p>
            <div className="imageIcons">
              <div>
                <button
                  id="x"
                  className="twitter"
                  onClick={() => handleBudgetSelect("")}
                >
                  <div className="s">₦250,000-₦500,000</div>
                </button>
              </div>

              <div>
                <button
                  id="x"
                  className="twitter"
                  onClick={() => handleBudgetSelect("Behance")}
                >
                  <div className="s">₦500,000-₦1M</div>
                </button>
              </div>
              <div>
                <button
                  id="x"
                  className="twitter"
                  onClick={() => handleBudgetSelect("Friends")}
                >
                  <div className="s">₦1M+</div>
                </button>
              </div>

              <div>
                
              </div>
                </div>
                <textarea
              type="text"
              placeholder="Tell us about your project"
              className="inputFieldlast"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
                          
           

         
        </div>
     
       
          <div className="containerr99">
            <div className="joinBtnnn" onMouseEnter={toggleCircle3} onMouseLeave={toggleCircle3}>
                <div className={`circleLongg ${isCircleActive3 ? 'active' : ''}`}></div>
              
                <button onClick={handleGetInTouch} className="getInTouchButton">
                      <p className="btnOpenForm000">Submit</p>
                    </button>
              </div>
            </div>
          </div> 
               
      
            
        </div>
        </div>
      
    </div>
  );
};

export default Contact;
